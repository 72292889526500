<template functional>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    :class="['icon-print', data.class, data.staticClass]"
  >
    <!-- eslint-disable -->
    <path
      class="secondary fill-current"
      d="M5 8h14a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-5c0-1.1.9-2 2-2zm1 2a1 1 0 0 0 0 2h2a1 1 0 0 0 0-2H6z"
    />
    <!-- eslint-enable -->
    <path
      class="primary fill-current"
      d="M6 14h12v6a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1v-6zm0-6V4a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1v4H6z"
    />
  </svg>
</template>


<script>
export default {};
</script>
