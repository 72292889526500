<template>
  <component
    :is="tag"
    class="flex items-center"
    @focusin="isFocused = true"
    @focusout="isFocused = false"
    @mouseover="isHovered = true"
    @mouseleave="isHovered = false"
  >
    <UIDropdown
      :is-disabled="isDisabled"
      dropdown-classes="position-x-center"
      trigger-classes="button button-secondary button-sm button-text button-rounded-full flex items-center print:py-0"
      class="ml-1"
      @dropdown-press="onDropdownPress"
    >
      <template #trigger="{ isOpen }">
        <span class="h-26 w-26 inline-flex items-center justify-center">
          <IconAdd
            v-if="!profileType"
            class="h-full print:hidden"
          />
          <img
            v-else
            :src="profileTypeOptions.iconURL"
            alt="Profile"
            class="ml-5"
            :class="profileTypeOptions.classes.selected"
            @load="$emit('image-loaded')"
          >
        </span>
        <SmoothReflow
          tag="span"
          :options="{
            property: 'width',
            transition: 'width 200ms cubic-bezier(0.77, 0, 0.175, 1)',
          }"
        >
          <div
            v-if="showInputControls || isFocused || isOpen || isTouchDevice
            || profileDimensionsIsFocused || (profileType && !profileDimensions)"
            class="h-24 w-24 mr-1 print:hidden"
          >
            <IconChevronDown
              v-if="showInputControls || isFocused || isOpen || isTouchDevice || profileType || profileDimensions"
              class="h-full w-full text-gray-900 transform rotate-0 duration-300 ease-in-out-quart"
              :class="{ 'rotate-180': isOpen }"
            />
          </div>
        </SmoothReflow>
      </template>
      <div class="rounded-lg shadow-xs">
        <div class="grid grid-selection p-5 bg-white rounded-lg shadow-xl">
          <button
            v-for="(profileTypeOption, i) in profileTypesOptions"
            :key="`profile-type-${i}`"
            type="button"
            :disabled="profileTypeOption.value === profileType || isDisabled"
            class="h-40 w-40 flex items-center justify-center rounded-full border border-transparent
            transition-colors duration-150 ease-in-out-quart hover:bg-gray-200 focus:outline-none
            focus:shadow-outline focus:bg-gray-200"
            :class="{
              'bg-gray-200 border-gray-800 cursor-auto': profileTypeOption.value === profileType,
            }"
            @click="onProfileTypeSelection(profileTypeOption)"
          >
            <img
              :src="profileTypeOption.iconURL"
              :alt="profileTypeOption.value"
              class="p-4"
              :class="profileTypeOption.classes.listing"
            >
          </button>
        </div>
      </div>
    </UIDropdown>
    <label class="mx-4">
      <span class="sr-only">Dimensionen</span>
      <input
        :disabled="isDisabled"
        ref="profile-dimensions"
        type="text"
        :placeholder="(showInputControls && !profileDimensions) || profileDimensionsIsFocused
        || (profileType && !profileDimensions) ? 'Dimensionen' : ''"
        :value="profileDimensions"
        class="input-text h-28 w-full border-b border-transparent print:border-none"
        :class="{
          'border-gray-400': showInputControls,
        }"
        @input="onProfileDimensionsInput($event)"
        @keyup.enter="onInputEnterPress"
        @keydown.enter.prevent
        @focusin="profileDimensionsIsFocused = true"
        @focusout="onProfileDimensionsFocusOut"
      >
    </label>
    <div v-if="!isDisabled" class="flex-shrink-0 w-30 mt-2 mr-2 print:hidden">
      <Transition name="transition-fade-slow">
        <button
          v-show="profileType || profileDimensions"
          type="button"
          title="Entfernen"
          class="button button-secondary button-text button-rounded-full flex items-center justify-center"
          @click="onClearPress"
        >
          <IconClose class="h-30 w-30 text-gray-800" />
        </button>
      </Transition>
    </div>
  </component>
</template>


<script>
import resolveConfig from 'tailwindcss/resolveConfig';
import tailwindConfig from '@/../tailwind.config';
import { useMediaQuery } from '@/composables/useMediaQuery';
import SmoothReflow from '@/components/SmoothReflow';
import IconAdd from '@/components/icon/IconAdd';
import IconClose from '@/components/icon/IconClose';
import IconChevronDown from '@/components/icon/IconChevronDown';
import UIDropdown from '@/components/UI/UIDropdown';
import profileTypes from '@/helpers/profileTypes';


const themeConfig = resolveConfig(tailwindConfig);


export default {
  props: {
    tag: {
      type: String,
      default: 'div',
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    profileType: {
      type: [File, Number, String],
      default: '',
    },
    profileDimensions: {
      type: String,
      default: '',
    },
    showInputControls: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    SmoothReflow,
    IconAdd,
    IconClose,
    IconChevronDown,
    UIDropdown,
  },
  setup() {
    const isTouchDevice = useMediaQuery(themeConfig.theme.screens['touch-device'].raw);

    return {
      isTouchDevice,
    };
  },
  data: () => ({
    isHovered: false,
    isFocused: false,
    profileDimensionsIsFocused: false,
  }),
  mounted() {
    if (!this.profileType && this.profileDimensions) this.$emit('image-loaded');
  },
  computed: {
    profileTypesOptions() {
      return Object.entries(profileTypes)
        .sort((a, b) => a[1].displayOrder - b[1].displayOrder)
        .map(([value, profileType]) => ({ value, iconURL: profileType.iconURL, classes: profileType.classes }));
    },
    profileTypeOptions() {
      return profileTypes[this.profileType];
    },
  },
  methods: {
    onInputEnterPress() {
      this.$emit('add');
      this.$refs['profile-dimensions'].blur();
    },
    onDropdownPress() {
      if (this.profileDimensions) this.$emit('add');
      else this.$refs['profile-dimensions'].focus();
    },
    onProfileDimensionsFocusOut() {
      this.profileDimensionsIsFocused = false;
      if (this.profileType || this.profileDimensions) this.$emit('add');
    },
    onProfileTypeSelection(profileTypeOption) {
      this.$emit('update:profile-type', profileTypeOption.value);
      this.$emit('input');
    },
    onProfileDimensionsInput(event) {
      this.$emit('update:profile-dimensions', event.target.value);
      this.$emit('input');
    },
    onClearPress() {
      this.$emit('clear');
      this.$emit('input');
    },
  },
};
</script>


<style>
.grid-selection {
  grid-template-columns: repeat(5, 1fr);
}

.position-x-center {
  right: 50%;
  transform: translate(50%, 0);
}
</style>
