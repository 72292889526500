<template>
  <textarea
    ref="text-area"
    :value="value"
    @input="onInput"
    @change="$emit('change', value)"
    @keydown="onKeyDown"
  />
</template>


<script>
export default {
  props: {
    value: {
      type: String,
      default: '',
    },
    maxLines: {
      type: [Number, String],
      default: '',
    },
  },
  methods: {
    onInput(event) {
      this.$emit('input', event.target.value);
      this.$refs['text-area'].style.height = '';
      this.$refs['text-area'].style.height = `${this.$refs['text-area'].scrollHeight}px`;
    },
    onKeyDown(event) {
      const matchedLineBreaks = this.value.match(/\r?\n|\r/g);
      const lineCount = matchedLineBreaks ? matchedLineBreaks.length + 1 : 0;

      if (event.keyCode === 13 && lineCount >= parseInt(this.maxLines, 10)) {
        event.preventDefault();
      }
    },
  },
};
</script>


<style>
</style>
