import Vue from 'vue';
import filterByStatus from '@/helpers/functions/filterByStatus';
import searchableHumanReadableId from '@/helpers/searchableHumanReadableId';

const orderDateLocale = 'de-CH';
const orderDateFormat = {
  day: '2-digit',
  month: '2-digit',
  year: 'numeric',
};

function normalizeOrderData(order) {
  let normTreatment = {};
  let normSpecial = {};
  let normTransportDelivery = {};
  let normTransportReturnDelivery = {};

  if (order?.treatment !== undefined) {
    const { treatment } = order;

    normTreatment = {
      // eslint-disable-next-line max-len
      ...(treatment.hotDipGalvanizingISO1461 !== undefined && {
        hotDipGalvanizingISO1461: !!treatment.hotDipGalvanizingISO1461,
      }),
      ...(treatment.DASt022TrustZone?.length && { DASt022TrustZone: treatment.DASt022TrustZone }),
      // eslint-disable-next-line max-len
      ...(treatment.onlyPickleZincAndBackBlack !== undefined && {
        onlyPickleZincAndBackBlack: !!treatment.onlyPickleZincAndBackBlack,
      }),
      ...(treatment.duroplexCoating !== undefined && {
        duroplexCoating: !!treatment.duroplexCoating,
      }),
      ...(treatment.thermoplexCoating !== undefined && {
        thermoplexCoating: !!treatment.thermoplexCoating,
      }),
      ...(treatment.duroThermoShade && { duroThermoShade: `${treatment.duroThermoShade}` }),
      ...(treatment.SPlusZ !== undefined && { SPlusZ: !!treatment.SPlusZ }),
      ...(treatment.ferrostyleCoatingN !== undefined && {
        ferrostyleCoatingN: !!treatment.ferrostyleCoatingN,
      }),
      ...(treatment.ferrostyleCoatingP !== undefined && {
        ferrostyleCoatingP: !!treatment.ferrostyleCoatingP,
      }),
      ...(treatment.ferrostyleShade && { ferrostyleShade: `${treatment.ferrostyleShade}` }),
      ...(treatment.indoorApplication !== undefined && {
        indoorApplication: !!treatment.indoorApplication,
      }),
      ...(treatment.outdoorApplication !== undefined && {
        outdoorApplication: !!treatment.outdoorApplication,
      }),
      ...(treatment.galvanicZincPlating !== undefined && {
        galvanicZincPlating: !!treatment.galvanicZincPlating,
      }),
      ...(treatment.brightChromePlating !== undefined && {
        brightChromePlating: !!treatment.brightChromePlating,
      }),
      ...(treatment.blueChromating !== undefined && { blueChromating: !!treatment.blueChromating }),
      ...(treatment.EN1090 !== undefined && { EN1090: !!treatment.EN1090 }),
      ...(treatment.corrosivityCategory?.length && {
        corrosivityCategory: treatment.corrosivityCategory,
      }),
      ...(treatment.preparationDegree?.length && {
        preparationDegree: treatment.preparationDegree,
      }),
      ...(treatment.preparationDegreeTest?.length && {
        preparationDegreeTest: treatment.preparationDegreeTest,
      }),
    };
  }
  if (order?.special !== undefined) {
    const { special } = order;

    normSpecial = {
      ...(special.cleanThread !== undefined && { cleanThread: !!special.cleanThread }),
      ...(special.cleanThreadIn !== undefined && { cleanThreadIn: !!special.cleanThreadIn }),
      ...(special.cleanThreadOut !== undefined && { cleanThreadOut: !!special.cleanThreadOut }),
      ...(special.cleanThreadCount !== undefined && {
        cleanThreadCount: parseInt(special.cleanThreadCount, 10),
      }),
      ...(special.QS !== undefined && { QS: !!special.QS }),
      ...(special.measurementProtocol !== undefined && {
        measurementProtocol: !!special.measurementProtocol,
      }),
      ...(special.measurementProtocolAdvanced !== undefined && {
        measurementProtocolAdvanced: !!special.measurementProtocolAdvanced,
      }),
      ...(special.drilledInternalHoles !== undefined && {
        drilledInternalHoles: !!special.drilledInternalHoles,
      }),
      ...(special.closeHoles !== undefined && { closeHoles: !!special.closeHoles }),
      ...(special.closeHolesCount !== undefined && {
        closeHolesCount: parseInt(special.closeHolesCount, 10),
      }),
      // eslint-disable-next-line max-len
      ...(special.noAdditionallyDrilledHoles !== undefined && {
        noAdditionallyDrilledHoles: !!special.noAdditionallyDrilledHoles,
      }),
      ...(special.removeZincOnly !== undefined && { removeZincOnly: !!special.removeZincOnly }),
      ...(special.burnOffPaintOnly !== undefined && {
        burnOffPaintOnly: !!special.burnOffPaintOnly,
      }),
      ...(special.removePaintAndZinc !== undefined && {
        removePaintAndZinc: !!special.removePaintAndZinc,
      }),
      ...(special.euroPalletsCount !== undefined && {
        euroPalletsCount: parseInt(special.euroPalletsCount, 10),
      }),
      ...(special.palletFramesCount !== undefined && {
        palletFramesCount: parseInt(special.palletFramesCount, 10),
      }),
      // eslint-disable-next-line max-len
      ...(special.halfPalletFramesCount !== undefined && {
        halfPalletFramesCount: parseInt(special.halfPalletFramesCount, 10),
      }),
      // eslint-disable-next-line max-len
      ...(special.disposablePalletsCount !== undefined && {
        disposablePalletsCount: parseInt(special.disposablePalletsCount, 10),
      }),
      ...(special.otherDescription && { otherDescription: `${special.otherDescription}` }),
    };
  }
  if (order?.transportDelivery !== undefined) {
    const { transportDelivery } = order;

    normTransportDelivery = {
      // eslint-disable-next-line max-len
      ...(transportDelivery.byGalvaswissLorry !== undefined && {
        byGalvaswissLorry: !!transportDelivery.byGalvaswissLorry,
      }),
      ...(transportDelivery.pickUpByHimself !== undefined && {
        pickUpByHimself: !!transportDelivery.pickUpByHimself,
      }),
    };
  }
  if (order?.transportReturnDelivery !== undefined) {
    const { transportReturnDelivery } = order;

    normTransportReturnDelivery = {
      // eslint-disable-next-line max-len
      ...(transportReturnDelivery.byGalvaswissLorry !== undefined && {
        byGalvaswissLorry: !!transportReturnDelivery.byGalvaswissLorry,
      }),
      // eslint-disable-next-line max-len
      ...(transportReturnDelivery.pickUpByHimself !== undefined && {
        pickUpByHimself: !!transportReturnDelivery.pickUpByHimself,
      }),
    };
  }

  return {
    // Order document metadata
    ...(order.createdTime &&
      typeof order.createdTime.toDate === 'function' && {
        createdTime: order.createdTime.toDate(),
        // Additional metadata (local only)
        createdTimeLocaleDate: order.createdTime
          .toDate()
          .toLocaleDateString(orderDateLocale, orderDateFormat),
      }),
    ...(order.modifiedTime &&
      typeof order.modifiedTime.toDate === 'function' && {
        modifiedTime: order.modifiedTime.toDate(),
      }),
    ...(order.createdBy && { createdBy: `${order.createdBy}` }),
    ...(order.humanReadableId && {
      humanReadableId: `${order.humanReadableId}`,
      humanReadableIdForSearch: searchableHumanReadableId(`${order.humanReadableId}`),
    }),
    // Additional metadata (local only)
    ...(order.status !== undefined && { status: parseInt(order.status, 10) }),
    ...(order.location !== undefined && { location: order.location }),
    // Order document header
    ...(order.billingAddress && { billingAddress: order.billingAddress }),
    ...(order.companyName && { companyName: order.companyName }),
    ...(order.shippingAddress && { shippingAddress: order.shippingAddress }),
    ...(order.phoneNumberForQuestions && {
      phoneNumberForQuestions: `${order.phoneNumberForQuestions}`,
    }),
    ...(order.offerNumber && { offerNumber: `${order.offerNumber}` }),
    ...(order.deliveryDate &&
      typeof order.deliveryDate.toDate === 'function' && {
        deliveryDate: order.deliveryDate.toDate(),
      }),
    ...(order.pickupDate &&
      typeof order.pickupDate.toDate === 'function' && {
        pickupDate: order.pickupDate.toDate(),
      }),
    ...(order.orderDate &&
      typeof order.orderDate.toDate === 'function' && { orderDate: order.orderDate.toDate() }),
    ...(order.orderNumber && { orderNumber: `${order.orderNumber}` }),
    ...(order.commission && { commission: `${order.commission}` }),
    ...(order.deliverySlipNumber && { deliverySlipNumber: `${order.deliverySlipNumber}` }),
    ...(order.orderComments && { orderComments: `${order.orderComments}` }),
    // Order document metal components
    ...(order.metalComponents?.length && { metalComponents: order.metalComponents }),
    // Order document options
    ...(Object.keys(normTreatment).length && { treatment: normTreatment }),
    ...(Object.keys(normTransportDelivery).length && { transportDelivery: normTransportDelivery }),
    ...(Object.keys(normTransportReturnDelivery).length && {
      transportReturnDelivery: normTransportReturnDelivery,
    }),
    ...(Object.keys(normSpecial).length && { special: normSpecial }),
    // TODO: Add humanReadableIdForSearch
  };
}

export default {
  namespaced: true,
  state: {
    loadingStatus: 'init',
    all: {},
  },
  getters: {
    sortedByCreatedTime: (state) =>
      Object.values(state.all).sort((a, b) => b.createdTime - a.createdTime),
    active: (state, getters) => filterByStatus(getters.sortedByCreatedTime, 0),
    pending: (state, getters) => filterByStatus(getters.sortedByCreatedTime, 3),
    archived: (state, getters) => filterByStatus(getters.sortedByCreatedTime, 1),
  },
  mutations: {
    RESET_STATE(state) {
      state.loadingStatus = 'init';
      state.all = {};
    },
    SET_LOADING_STATUS(state, loadingStatus) {
      state.loadingStatus = loadingStatus;
    },
    SET_ORDER(state, { id, ...data }) {
      const orderData = { ...data };

      // Read-only id property
      Object.defineProperty(orderData, 'id', {
        value: id,
        enumerable: true,
      });

      Vue.set(state.all, id, orderData);
    },
    REMOVE_ORDER(state, id) {
      Vue.delete(state.all, id);
    },
  },
  actions: {
    async resetState({ commit }) {
      commit('RESET_STATE');
    },
    onOrdersSnapshot({ state, commit }, { snapshot, replaceState = false }) {
      let docIdsToRemove = []; // Documents to remove
      if (replaceState) {
        if (typeof replaceState === 'boolean') docIdsToRemove = Object.keys(state.all);
        else if (typeof replaceState === 'object' && replaceState !== null) {
          console.log(replaceState);
          // TODO: Handle replaceState
          // docIdsToRemove = Object.keys(state.all);
        }
        // replaceState: { status: 0 }
      }

      // Set loading status
      if (state.loadingStatus === 'init') commit('SET_LOADING_STATUS', 'init-loading');
      else commit('SET_LOADING_STATUS', 'snapshot-loading');

      snapshot.docChanges().forEach((snapshotChange) => {
        if (snapshotChange.type === 'added' || snapshotChange.type === 'modified') {
          commit('SET_ORDER', {
            id: snapshotChange.doc.id,
            ...normalizeOrderData(snapshotChange.doc.data()),
          });

          if (replaceState)
            docIdsToRemove = docIdsToRemove.filter((docId) => docId !== snapshotChange.doc.id);
        } else if (snapshotChange.type === 'removed') {
          commit('REMOVE_ORDER', snapshotChange.doc.id);
        }
      });

      // Cleanup existing state
      // console.log('# Cleaning up orders :', docIdsToRemove);
      docIdsToRemove.forEach((docIdToRemove) => commit('REMOVE_TEAM_MEMBER', docIdToRemove));

      // Set loading status
      if (state.loadingStatus === 'init-loading') commit('SET_LOADING_STATUS', 'init-loaded');
      else commit('SET_LOADING_STATUS', 'snapshot-loaded');
    },
    onOrderSnapshot({ state, commit }, { id, ...orderData }) {
      commit('SET_LOADING_STATUS', 'snapshot-loading');

      commit('SET_ORDER', {
        id,
        ...normalizeOrderData(orderData),
      });

      if (state.loadingStatus === 'snapshot-loading')
        commit('SET_LOADING_STATUS', 'snapshot-loaded');
    },
  },
};
