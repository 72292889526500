export const defaultRowCount = 3;

export const minMetalComponentRows = 3;
export const maxMetalComponentRows = 50;
export const maxProfilesPerMetalComponent = 3;

export const defaultProfileTypeWithDimensions = {
  type: '',
  dimensions: '',
};

export const defaultMetalComponent = {
  position: '',
  count: '',
  materialDesignation: '',
  imageStoragePath: '',
  imageURL: '',
  imageFile: null,
  length: '',
  width: '',
  depth: '',
};
