<template>
    <select
      v-bind="$attrs"
      :value="value"
      selected="selected"
      class="w-60 h-22 pl-0 pr-10 cursor-pointer input-text text-sm"
      :disabled="isDisabled && inputField === 'model'"
      @input="$emit('input', $event.target.value)"
      @change="$emit('change', value)"
    >
      <option
        v-for="(option, index) in options"
        :key="index"
        :value="option.value"
      >
        <span v-if="!option.text">{{ option.value }}</span>
        <span v-else>{{ option.text }}</span>
      </option>
    </select>
</template>


<script>
export default {

  props: {
    value: {
      type: [String, Number],
      default: '',
    },
    options: {
      type: [Array, Object],
      default() {
        return [];
      },
    },
    label: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: 'CH',
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    inputField: {
      type: String,
      default: '',
    },
  },
};
</script>
