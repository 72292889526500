// Converts the humanReadableId from CLK-1 to CLK-00001 so that the search matches the exact ID first
export default function (id) {
  const idMinNumber = 5;
  const idParts = id.split('-');
  let idNumberPart = '';

  for (let i = 0; i < idMinNumber; i += 1) {
    if (idParts[idParts.length - 1][i] === undefined) {
      idNumberPart = `0${idNumberPart}`;
    } else {
      idNumberPart = `${idNumberPart}${idParts[idParts.length - 1][i]}`;
    }
  }

  return `${idParts[0]}-${idNumberPart}`;
}
