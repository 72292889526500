export default function () {
  // try {
  //   if (!window.print) {
  //     // Print for Safari browser
  //     document.execCommand('print', false, null);
  //   } else {
  //     window.print();
  //   }
  // } catch {
  //   window.print();
  // }
  var isChromium = window.chrome;
  var isIOSChrome = window.navigator.userAgent.match("CriOS");
  // console.log(isChromium);
  // console.log(isIOSChrome);
  // debugger;
  if (isChromium || isIOSChrome) {
    window.print();
  } else if (navigator.userAgent.indexOf("Safari") != -1) {
    document.execCommand('print', false, null);
  } else {
    window.print();
  }
}
