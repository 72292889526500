var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(_vm.tag,{tag:"component",staticClass:"flex items-center",on:{"focusin":function($event){_vm.isFocused = true},"focusout":function($event){_vm.isFocused = false},"mouseover":function($event){_vm.isHovered = true},"mouseleave":function($event){_vm.isHovered = false}}},[_c('UIDropdown',{staticClass:"ml-1",attrs:{"is-disabled":_vm.isDisabled,"dropdown-classes":"position-x-center","trigger-classes":"button button-secondary button-sm button-text button-rounded-full flex items-center print:py-0"},on:{"dropdown-press":_vm.onDropdownPress},scopedSlots:_vm._u([{key:"trigger",fn:function(ref){
var isOpen = ref.isOpen;
return [_c('span',{staticClass:"h-26 w-26 inline-flex items-center justify-center"},[(!_vm.profileType)?_c('IconAdd',{staticClass:"h-full print:hidden"}):_c('img',{staticClass:"ml-5",class:_vm.profileTypeOptions.classes.selected,attrs:{"src":_vm.profileTypeOptions.iconURL,"alt":"Profile"},on:{"load":function($event){return _vm.$emit('image-loaded')}}})],1),_c('SmoothReflow',{attrs:{"tag":"span","options":{
          property: 'width',
          transition: 'width 200ms cubic-bezier(0.77, 0, 0.175, 1)',
        }}},[(_vm.showInputControls || _vm.isFocused || isOpen || _vm.isTouchDevice
          || _vm.profileDimensionsIsFocused || (_vm.profileType && !_vm.profileDimensions))?_c('div',{staticClass:"h-24 w-24 mr-1 print:hidden"},[(_vm.showInputControls || _vm.isFocused || isOpen || _vm.isTouchDevice || _vm.profileType || _vm.profileDimensions)?_c('IconChevronDown',{staticClass:"h-full w-full text-gray-900 transform rotate-0 duration-300 ease-in-out-quart",class:{ 'rotate-180': isOpen }}):_vm._e()],1):_vm._e()])]}}])},[_c('div',{staticClass:"rounded-lg shadow-xs"},[_c('div',{staticClass:"grid grid-selection p-5 bg-white rounded-lg shadow-xl"},_vm._l((_vm.profileTypesOptions),function(profileTypeOption,i){return _c('button',{key:("profile-type-" + i),staticClass:"h-40 w-40 flex items-center justify-center rounded-full border border-transparent\n          transition-colors duration-150 ease-in-out-quart hover:bg-gray-200 focus:outline-none\n          focus:shadow-outline focus:bg-gray-200",class:{
            'bg-gray-200 border-gray-800 cursor-auto': profileTypeOption.value === _vm.profileType,
          },attrs:{"type":"button","disabled":profileTypeOption.value === _vm.profileType || _vm.isDisabled},on:{"click":function($event){return _vm.onProfileTypeSelection(profileTypeOption)}}},[_c('img',{staticClass:"p-4",class:profileTypeOption.classes.listing,attrs:{"src":profileTypeOption.iconURL,"alt":profileTypeOption.value}})])}),0)])]),_c('label',{staticClass:"mx-4"},[_c('span',{staticClass:"sr-only"},[_vm._v("Dimensionen")]),_c('input',{ref:"profile-dimensions",staticClass:"input-text h-28 w-full border-b border-transparent print:border-none",class:{
        'border-gray-400': _vm.showInputControls,
      },attrs:{"disabled":_vm.isDisabled,"type":"text","placeholder":(_vm.showInputControls && !_vm.profileDimensions) || _vm.profileDimensionsIsFocused
      || (_vm.profileType && !_vm.profileDimensions) ? 'Dimensionen' : ''},domProps:{"value":_vm.profileDimensions},on:{"input":function($event){return _vm.onProfileDimensionsInput($event)},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.onInputEnterPress.apply(null, arguments)},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();},"focusin":function($event){_vm.profileDimensionsIsFocused = true},"focusout":_vm.onProfileDimensionsFocusOut}})]),(!_vm.isDisabled)?_c('div',{staticClass:"flex-shrink-0 w-30 mt-2 mr-2 print:hidden"},[_c('Transition',{attrs:{"name":"transition-fade-slow"}},[_c('button',{directives:[{name:"show",rawName:"v-show",value:(_vm.profileType || _vm.profileDimensions),expression:"profileType || profileDimensions"}],staticClass:"button button-secondary button-text button-rounded-full flex items-center justify-center",attrs:{"type":"button","title":"Entfernen"},on:{"click":_vm.onClearPress}},[_c('IconClose',{staticClass:"h-30 w-30 text-gray-800"})],1)])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }