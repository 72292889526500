export default [
  'Konstruktionen',
  'Abdeckung aus',
  'Ausleger',
  'Anschlag',
  'Konsole',
  'Blumenkastenhalter',
  'Bock',
  'Chassis / Zubehör',
  'Bodenhülse',
  'Flansch',
  'Gestell aus',
  'Kandelaber',
  'Leiter',
  'Kloben',
  'Leitplanken',
  'Wärmetauscher',
  'Halter',
  'Haken',
  'Platte',
  'Dach',
  'Briede',
  'Deckel',
  'Traverse',
  'Kugel',
  'Balkenschuh',
  'Geländer',
  'Balkongeländer',
  'Brüstungsgeländer',
  'Eckgeländer',
  'Geländerteile',
  'Treppengeländer',
  'Wendeltreppegeländer',
  'Rahmen',
  'Balkonrahmen',
  'Fensterrahmen',
  'Geländerrahmen',
  'Podestrahmen',
  'Rohrrahmen',
  'Profilrahmen',
  'Tafelrahmen',
  'Tür- / Torrahmen',
  'Vordachrahmen',
  'Winkelrahmen',
  '3/4 Rahmen',
  'Stütze',
  'Rohrstütze',
  'Balkonstütze',
  'Pfosten',
  'Geländerpfosten',
  'Tür- / Torpfosten',
  'Träger',
  'Binder',
  'Dachpfette',
  'Dachträger',
  'Längsträger',
  'Pfette',
  'Podestträger',
  'Querträger',
  'Träger',
  'T-Träger',
  'U-Träger',
  'Treppen',
  'Treppe',
  'Treppenwangen',
  'Wendeltreppe',
  'Roste',
  'Roste',
  'Gitterroste',
  'Treppenstufenroste',
  'Profile',
  'C-Profil',
  'Flacheisen',
  'Rundeisen',
  'Winkel',
  'Z-Profil',
  'Rohr',
  'T-Profil',
  'U-Profil',
  'Jansen Profil',
  'Schiene',
  'Laufschiene',
  'Vierkantrohr',
  'Bleche',
  'Abdeckbleche',
  'Abkantbleche',
  'Blechdeckel',
  'Blechkasten',
  'Blechprofil',
  'Lochblech',
  'Riffelblech',
  'Tränenblech',
  'Stangen / Rohre',
  'Stange',
  'Rohr',
  'Flanschrohr',
  'Zugstange',
  'Strebe',
  'Türe / Tore',
  'Türe',
  'Türrahmen',
  'Tor',
  'Torrahmen',
  'Flügeltor',
  'Gartentor',
  'Schiebetor',
  'Handlauf',
  'Handlauf',
  'Treppenhandlauf',
  'Gitter',
  'Fenstergitter',
  'Absperrgitter',
  'Trenngitter',
  'Schutzgitter',
  'Gitterrost',
  'Gassner',
  'Rohrstütze',
  'Joche',
  'Stalleinrichtung',
  'Liegeboxen',
  'Trenngitter',
  'Fensterrahmen',
  'Türrahmen',
  'SBB',
  'Abfangjoche',
  'Fahrbahnmasten',
  'A-Stützen',
  'Behälter / Deckel',
  'Fass 1000 lt',
  'Fass 2000 lt',
  'Fass 3000 lt',
  'Fass 4000 lt',
  'Fass 6000 lt',
  'Fass 7000 lt',
  'Fass 8000 lt',
  'Fass 10000 lt',
  'Fass 12000 lt',
  'Fass 14000 lt',
  'Fass Deckel',
  'Domdeckel',
  'Container',
  'Container 800 lt',
  'Container 400 lt',
  'Doppelmayr',
  'Fahrbahnanschluss',
  'Fahrbahnen',
  'Hängebahn',
  'Querträger',
  'Steher',
  'Bügel',
  'Portalrahmen',
  'Reifenträger',
  'Seilscheibenträger',
  'Sesselführung',
  'Träger',
  'Motorkonsole',
  'Kolb /Burri',
  'Kandalaber',
  'Peitschenkandalaber',
  'Stehkandalaber',
  'Türchen Nr',
  'Traversen Nr',
  'Verlängerung Nr',
  'Ausleger Nr',
  'Bogenausleger Nr',
  'Zusätze',
  'Duplex',
  'Gewinde reinigen',
  'Löcher bürsten',
  'Löcher bohren',
  'Garantie',
  'Tourenwechsel',
];
