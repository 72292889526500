<template functional>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    :class="['icon-chevron-down', data.class, data.staticClass]"
  >
    <path
      fill-rule="evenodd"
      d="M15.3 10.3a1 1 0 0 1 1.4 1.4l-4 4a1 1 0 0 1-1.4 0l-4-4a1 1 0 0 1 1.4-1.4l3.3 3.29 3.3-3.3z"
      class="primary fill-current"
    />
  </svg>
</template>


<script>
export default {};
</script>
