<template functional>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    class="icon-document-notes"
    :class="['icon-document-notes', data.class, data.staticClass]"
  >
    <!-- eslint-disable -->
    <path
      d="M6 2h6v6c0 1.1.9 2 2 2h6v10a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V4c0-1.1.9-2 2-2zm2 11a1 1 0 0 0 0 2h8a1 1 0 0 0 0-2H8zm0 4a1 1 0 0 0 0 2h4a1 1 0 0 0 0-2H8z"
      class="primary fill-current"
    />
    <!-- eslint-enable -->
    <polygon
      points="14 2 20 8 14 8"
      class="secondary"
    />
  </svg>
</template>


<script>
export default {};
</script>
