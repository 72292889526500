/*
** TailwindCSS Configuration File
**
** Docs: https://tailwindcss.com/docs/configuration
** Default: https://github.com/tailwindcss/tailwindcss/blob/master/stubs/defaultConfig.stub.js
*/
const defaultTheme = require('tailwindcss/defaultTheme');

module.exports = {
  theme: {
    screens: {
      'mobile-s': '320px',
      'mobile-m': '375px',
      'mobile-l': '425px',
      tablet: '768px',
      laptop: '1024px',
      'laptop-l': '1440px',
      '4k': '2560px',
    },
    colors: {
      /* Base colors */
      transparent: 'transparent',
      white: 'var(--base-color-white)',
      black: 'var(--base-color-black)',
      gray: {
        50: 'var(--base-color-gray-50)',
        100: 'var(--base-color-gray-100)',
        200: 'var(--base-color-gray-200)',
        300: 'var(--base-color-gray-300)',
        350: 'var(--base-color-gray-350)',
        400: 'var(--base-color-gray-400)',
        500: 'var(--base-color-gray-500)',
        600: 'var(--base-color-gray-600)',
        700: 'var(--base-color-gray-700)',
        800: 'var(--base-color-gray-800)',
        900: 'var(--base-color-gray-900)',
      },
      /* Theme colors */
      primary: {
        light: 'var(--theme-color-primary-light)',
        default: 'var(--theme-color-primary)',
      },
      secondary: {
        light: 'var(--theme-color-secondary-light)',
        default: 'var(--theme-color-secondary)',
        dark: 'var(--theme-color-secondary-dark)',
      },
      success: {
        light: 'var(--theme-color-success-light)',
        default: 'var(--theme-color-success)',
        dark: 'var(--theme-color-success-dark)',
      },
      danger: {
        light: 'var(--theme-color-danger-light)',
        default: 'var(--theme-color-danger)',
        dark: 'var(--theme-color-danger-dark)',
      },
      link: 'var(--theme-color-link)',
    },
    spacing: {
      0: '0',
      '1/2': '50%',
      '4/100': '4%',
      '5/100': '5%',
      '6/100': '6%',
      '7/100': '7%',
      '8/100': '8%',
      '9/100': '9%',
      '12/100': '12%',
      '14/100': '14%',
      '23/100': '23%',
      '26/100': '26%',
      '31/100': '31%',
      '34/100': '34%',
      '35/100': '35%',
      '87/100': '87%',
      1: '0.0625rem',
      2: '0.125rem',
      3: '0.1875rem',
      4: '0.25rem',
      5: '0.3125rem',
      6: '0.375rem',
      7: '0.4375rem',
      8: '0.5rem',
      10: '0.6875rem',
      12: '0.75rem',
      14: '0.875rem',
      15: '0.9375rem',
      18: '1.125rem',
      20: '1.25rem',
      22: '1.375rem',
      24: '1.5rem',
      25: '1.5625rem',
      26: '1.625rem',
      28: '1.75rem',
      29: '1.8125rem',
      30: '1.875rem',
      32: '2rem',
      34: '2.125rem',
      35: '2.1875rem',
      36: '2.25rem',
      38: '2.375rem',
      40: '2.5rem',
      42: '2.625rem',
      45: '2.8125rem',
      50: '3.125rem',
      55: '3.4375rem',
      60: '3.75rem',
      64: '4rem',
      70: '4.375rem',
      72: '4.5rem',
      74: '4.625rem',
      85: '5.3125rem',
      100: '6.25rem',
      110: '6.875rem',
      125: '7.8125rem',
      140: '8.75rem',
      150: '9.375rem',
      158: '9.875rem',
      180: '11.25rem',
      200: '12.5rem',
      210: '13.125rem',
      250: '15.625rem',
      300: '18.75rem',
      400: '25rem',
      430: '26.875rem',
      470: '29.375rem',
      500: '31.25rem',
      1024: '64rem',
      header: 'var(--theme-spacing-header)',
      'without-header': 'calc(100% - var(--theme-spacing-header))',
      sidebar: 'var(--theme-spacing-sidebar)',
      main: 'var(--theme-spacing-main)',
    },
    borderColor: (theme) => ({
      inherit: 'inherit',
      ...theme('colors'),
      default: theme('colors.gray.350', 'currentColor'),
    }),
    borderWidth: {
      default: '1px',
      0: '0',
      2: '2px',
      3: '3px',
    },
    minHeight: (theme) => ({
      0: '0',
      full: '100%',
      35: theme('spacing.35'),
    }),
    minWidth: (theme) => ({
      0: '0',
      200: theme('spacing.200'),
      full: '100%',
      content: 'max-content',
    }),
    maxWidth: (theme) => ({
      250: theme('spacing.250'),
      300: theme('spacing.300'),
      400: theme('spacing.400'),
      430: theme('spacing.430'),
      500: theme('spacing.500'),
      1024: theme('spacing.1024'),
      main: theme('spacing.main'),
    }),
    fontFamily: {
      sans: 'Arial, Helvetica, sans-serif',
    },
    fontSize: {
      tiny: '.6875rem',
      ...defaultTheme.fontSize,
    },
    inset: (theme) => ({
      0: '0',
      28: theme('spacing.28'),
      110: theme('spacing.110'),
      '-35': `-${theme('spacing.35')}`,
      header: theme('spacing.header'),
      auto: 'auto',
    }),
    letterSpacing: {
      ...defaultTheme.letterSpacing,
      extreme: '0.55em',
    },
    boxShadow: (theme) => ({
      ...defaultTheme.boxShadow,
      // outline: '0 0 1px 2px rgba(66, 153, 225, 0.85)',
      outline: `0 0 1px 2px ${theme('colors.secondary.default')}`,
      'gray-200-inset-l': `inset 1px 0 ${theme('colors.gray.200')}`,
      'gray-200-inset-r': `inset -1px 0 ${theme('colors.gray.200')}`,
      'danger-outline': `0 0 1px 3px ${theme('colors.danger.default')}`,
    }),
    zIndex: {
      '-1': -1,
      ...defaultTheme.zIndex,
    },
    fill: (theme) => ({
      current: 'currentColor',
      transparent: 'transparent',
      black: theme('colors.black'),
      white: theme('colors.white'),
      primary: {
        light: theme('colors.primary.light'),
        default: theme('colors.primary.default'),
      },
      secondary: {
        light: theme('colors.secondary.light'),
        default: theme('colors.secondary.default'),
        dark: theme('colors.secondary.dark'),
      },
      success: {
        light: theme('colors.success.light'),
        default: theme('colors.success.default'),
        dark: theme('colors.success.dark'),
      },
      danger: {
        light: theme('colors.danger.light'),
        default: theme('colors.danger.default'),
        dark: theme('colors.danger.dark'),
      },
      gray: {
        100: theme('colors.gray.100'),
        200: theme('colors.gray.200'),
        300: theme('colors.gray.300'),
        500: theme('colors.gray.500'),
        400: theme('colors.gray.400'),
        600: theme('colors.gray.600'),
        700: theme('colors.gray.700'),
        800: theme('colors.gray.800'),
      },
    }),
    transitionTimingFunction: {
      linear: 'linear',
      in: 'cubic-bezier(0.4, 0, 1, 1)',
      out: 'cubic-bezier(0, 0, 0.2, 1)',
      'in-out': 'cubic-bezier(0.4, 0, 0.2, 1)',
      // https://easings.net
      'in-quart': 'cubic-bezier(0.895, 0.03, 0.685, 0.22)',
      'out-quart': 'cubic-bezier(0.165, 0.84, 0.44, 1)',
      'in-out-quart': 'cubic-bezier(0.77, 0, 0.175, 1)',
    },
    extend: {
      screens: {
        print: { raw: 'print' },
        // Primary input device pointing accuracy
        'pointer-none': { raw: '(pointer: none)' },
        'pointer-coarse': { raw: '(pointer: coarse)' },
        'pointer-fine': { raw: '(pointer: fine)' },
        // Any input device pointing accuracy
        'any-pointer-none': { raw: '(any-pointer: none)' },
        'any-pointer-coarse': { raw: '(any-pointer: coarse)' },
        'any-pointer-fine': { raw: '(any-pointer: fine)' },
        // Primary input device hover capability
        'hover-none': { raw: '(hover: none)' },
        'hover-hover': { raw: '(hover: hover)' },
        // Any input device hover capability
        'any-hover-none': { raw: '(any-hover: none)' },
        'any-hover-hover': { raw: '(any-hover: hover)' },
        // Predefined presets
        // Smartphone, tablet, touchscreens
        'touch-device': { raw: '(hover: none) and (pointer: coarse)' },
        // Stylus-based screens
        'stylus-device': { raw: '(hover: none) and (pointer: fine)' },
        // Nintendo Wii controller, Microsoft Kinect
        'controller-device': { raw: '(hover: hover) and (pointer: coarse)' },
        // Mouse, touch pad
        'mouse-device': { raw: '(hover: hover) and (pointer: fine)' },
      },
    },
  },
  variants: {},
  corePlugins: {
    container: false,
    float: false,
    objectPosition: false,
    fontStyle: false,
  },
};
