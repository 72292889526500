<template>
  <label>
    <slot
      :file="value"
      :select-file="selectFile"
      :remove-file="removeFile"
    />
    <input
      v-bind="$attrs"
      ref="input-file"
      type="file"
      class="hidden"
      @change="handleFileChange"
    />
  </label>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    value: {
      type: File,
      default: null,
    },
  },
  methods: {
    selectFile() {
      this.$refs['input-file'].click();
    },
    handleFileChange(e) {
      this.$emit('input', e.target.files[0]);
      this.$emit('change', e.target.files[0]);
    },
    removeFile() {
      this.$emit('input', null);
    },
  },
};
</script>
