const profileTypeIconsBasePath = '/images/profile-types';


export default {
  '○': {
    displayOrder: 0,
    iconURL: `${profileTypeIconsBasePath}/circle.svg`,
    classes: {
      listing: 'h-26',
      selected: 'h-20',
    },
  },
  '◻': {
    displayOrder: 1,
    iconURL: `${profileTypeIconsBasePath}/square.svg`,
    classes: {
      listing: 'h-24',
      selected: 'h-18',
    },
  },
  '▭': {
    displayOrder: 2,
    iconURL: `${profileTypeIconsBasePath}/rectangle.svg`,
    classes: {
      listing: 'h-32',
      selected: 'h-20',
    },
  },
  U: {
    displayOrder: 3,
    iconURL: `${profileTypeIconsBasePath}/U.svg`,
    classes: {
      listing: 'h-24',
      selected: 'h-18',
    },
  },
  L: {
    displayOrder: 4,
    iconURL: `${profileTypeIconsBasePath}/L.svg`,
    classes: {
      listing: 'h-24',
      selected: 'h-18',
    },
  },
  '●': {
    displayOrder: 5,
    iconURL: `${profileTypeIconsBasePath}/circle-filled.svg`,
    classes: {
      listing: 'h-26',
      selected: 'h-20',
    },
  },
  '◼': {
    displayOrder: 6,
    iconURL: `${profileTypeIconsBasePath}/square-filled.svg`,
    classes: {
      listing: 'h-24',
      selected: 'h-18',
    },
  },
  '▬': {
    displayOrder: 7,
    iconURL: `${profileTypeIconsBasePath}/rectangle-filled.svg`,
    classes: {
      listing: 'h-32',
      selected: 'h-20',
    },
  },
  I: {
    displayOrder: 8,
    iconURL: `${profileTypeIconsBasePath}/I.svg`,
    classes: {
      listing: 'h-24',
      selected: 'h-18',
    },
  },
  T: {
    displayOrder: 9,
    iconURL: `${profileTypeIconsBasePath}/T.svg`,
    classes: {
      listing: 'h-24',
      selected: 'h-18',
    },
  },
};
